@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/resources/fonts/Nunito-regular.woff2') format('woff2'),
    url('/resources/fonts/Nunito-regular.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/resources/fonts/Nunito-bold.woff2') format('woff2'),
    url('/resources/fonts/Nunito-bold.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('/resources/fonts/Nunito-extrabold.woff2') format('woff2'),
    url('/resources/fonts/Nunito-extrabold.woff') format('woff');
}
