/* You can add global styles to this file, and also import other style files */

@use './fonts';
@use '../../../../shared/src/lib/styles/buttons';
@use '../../../../shared/src/lib/styles/crossed-out';

// Sakura
@use '../../../../shared/src/lib/styles/sakura-global-tokens';
@use './sakura-tokens-yallo';
@use '../../../../shared/src/lib/styles/material-theme-variables';

// Todo - to remove the following variables and point to Sakura
//ROOT STYLES
:root {
  // Angular Material
  --mdc-outlined-text-field-container-shape: 0.5rem;
  --mdc-icon-button-icon-size: 18px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-form-field-container-height: 54px;
  --mat-dialog-container-max-width: none;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);

  --mat-form-field-subscript-text-size: 0.875rem;
  --mdc-fab-container-shape: 9999px;

  // Moved sakura tokens
  --color-green-400: #00cf9d;
  --color-green-400-2: rgba(0, 207, 157, 0.2);

  --color-yellow-50: #fff7e1;
  --color-yellow-500: #ffca47;
  --color-yellow-500-2: rgba(255, 202, 71, 0.2);

  --color-blue-300: #68c9e8;
  --color-blue-300-2: rgba(104, 201, 232, 0.2);

  --color-red-50: #fde6e8;
  --color-red-400: #e54558;
  --color-red-400-2: rgba(229, 69, 88, 0.2);

  --color-neutral-300: #f2f5fa;
  --color-neutral-400: #d5dbe5;
  --color-neutral-400-2: rgba(213, 219, 229, 0.2);

  --color-border-negative: var(--color-red-400);
  --color-border-warning: var(--color-yellow-500);
  --color-border-positive: var(--color-green-400);
  --color-border-primary: var(--color-neutral-400);

  --color-background-tertiary: var(--color-neutral-300);

  // Old css variables
  font-family: Nunito, sans-serif;
  --color-dark-gray: #27303e;
  --color-gray-1: #5e6266;
  --color-gray-2: #9caab4;
  --color-gray-3: #dce0e3;
  --color-gray-4: #dee5eb;
  --color-gray-5: #f8fafc;
  --color-gray-6: #697480;
  --color-gray-7: #fdffff;
  --color-light: #f6f9fc;
  --color-pink: #e985b6;
  --color-purple: #7275b7;
  --color-midnight: #1e012c;
  --color-plum: #5a0185;
  --color-selective-yellow: #fab401;
  --color-thistle-purple: #a599ab;
  --color-red-darker: #bc0600;
  --color-red: #ed2a24;
  --color-red-light: #ff413b;
  --color-red-lighter: #ff413b;
  --color-green: #00cf9d;
  --color-petrol-dark: #29beba;
  --color-petrol: #00cccc;
  --color-blue: #0098c8;
  --color-blue-2: #1f93ba;
  --color-blue-darkened: #00789d;
  --color-gray-opacity: rgba(217, 217, 217, 0.53);
  --color-gray-4-opacity: rgba(222, 229, 235, 0.3);
  --color-gray-opacity-strong: rgba(220, 224, 227, 0.7);
  --color-gray: #666666;
  --color-primary-opacity-light: rgba(var(--color-primary-rgb), 0.2);

  --button-border-radius: 100px;
  --base-spacing: 16px;

  --color-nav-stop-1: rgba(0, 204, 204, 0.6);
  --color-nav-stop-2: rgba(0, 180, 180, 0.6);
  --color-nav-stop-3: rgba(0, 152, 200, 0.6);

  --color-primary: var(--color-petrol);
  --color-primary-2: var(--color-blue-darkened);
  --color-primary-lightened: #00eeee;
  --color-primary-lightened-opacity: #00eeee25;
  --color-secondary: var(--color-blue);
  --color-primary-rgb: 0, 204, 204;
  --color-primary-opacity: rgba(var(--color-primary-rgb), 0.08);
  // --primary-gradient: var(--petrol-gradient);
  --tv-gradient: linear-gradient(142.57deg, #1e012c 9.37%, #5a0185 99.93%);
  --tv-gradient-separator: linear-gradient(87.5deg, #a335d8 40%, #29003d 60%);
  --accent-gradient: linear-gradient(87.5deg, var(--color-primary-lightened) 40%, var(--color-blue-darkened) 60%);
  --color-nav: var(--basic-white);
  --color-nav-active: var(--basic-white);
  --nav-gradient: linear-gradient(
    87.5deg,
    var(--color-nav-stop-1) 5%,
    var(--color-nav-stop-2) 30%,
    var(--color-nav-stop-3) 80%
  );
  --nav-gradient-active: linear-gradient(87.5deg, var(--color-petrol) 0, var(--color-blue) 50%);
  --nav-gradient-promotion: linear-gradient(to right, var(--color-red-lighter), var(--color-red-darker) 80%);
  --color-dark: var(--color-dark-gray);
  --color-footer: var(--color-gray-2);
  --color-footer-active: var(--basic-white);

  --color-title: var(--color-dark);

  --color-featured: var(--color-red);

  --color-error: var(--color-red-light);

  --color-product-box-description: var(--color-dark);

  --color-bg-footer: var(--color-dark-gray);

  --color-button-card-list: var(--color-gray-opacity);

  --color-text-card-list: var(--color-dark);

  --color-table-header-rows: var(--color-dark);

  --color-table-header-columns: var(--color-dark);

  --color-centered-accordion-titles: var(--color-black);

  --color-theme: var(--color-primary);

  //GRADIENTS

  --theme-gradient: linear-gradient(87.5deg, var(--color-theme) 6.72%, var(--color-theme) 97.44%);
  --purple-gradient: linear-gradient(87.5deg, var(--color-purple) 6.72%, var(--color-purple) 97.44%);
  --pink-gradient: linear-gradient(87.5deg, var(--color-pink) 6.72%, var(--color-pink) 97.44%);
  --midnight-gradient: linear-gradient(142.57deg, var(--color-midnight) 9.37%, var(--color-plum) 99.93%);
  --light-gradient: linear-gradient(87.5deg, var(--basic-white) 40%, var(--basic-white) 60%);

  //BORDER
  --border-radius-product-boxes: 24px;

  //Icons
  --check-mark: url('/resources/check-mark-yallo.svg');
}

.button-sakura-sm {
  @apply w-[84px] h-10;
}

.button-sakura-md {
  @apply w-[89px] h-11;
}

.button-sakura-lg {
  @apply w-[107px] h-12;
}

.sakura-filled-button {
  @apply hover:shadow-sakura-sm focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-[--teal-500];
}

.sakura-outlined-button {
  --mdc-outlined-button-label-text-color: var(--blue-500);
  --mat-outlined-button-state-layer-color: var(--blue-500);
  @apply focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-[--teal-500];
}

.sakura-text-button {
  --mdc-text-button-label-text-color: var(--blue-500);
  --mat-text-button-state-layer-color: var(--blue-500);
  @apply focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-[--teal-500];
}

.sakura-tonal-button {
  --mdc-filled-button-container-color: var(--neutral-300);
  --mdc-filled-button-label-text-color: var(--blue-500);
  @apply focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-[--teal-500];

  &:hover {
    --mdc-filled-button-container-color: var(--blue-500-4);
  }
}
