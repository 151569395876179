:root {
  --color-background-primary: var(--neutral-50);
  --color-background-secondary: var(--neutral-100);
  --color-background-tertiary: var(--neutral-300);
  --color-background-primary-inverse: var(--neutral-800);
  --color-background-info-light: var(--blue-50);
  --color-background-info-dark: var(--blue-200);
  --color-background-warning-light: var(--yellow-50);
  --color-background-warning-dark: var(--yellow-300);
  --color-background-negative-light: var(--red-50);
  --color-background-negative-dark: var(--red-100);
  --color-background-positive-ligt: var(--green-50);
  --color-background-positive-dark: var(--green-200);
  /*borders*/
  --color-border-primary: var(--neutral-400);
  --color-border-secondary: var(--neutral-500);
  --color-border-info: var(--blue-300);
  --color-border-negative: var(--red-400);
  --color-border-positive: var(--green-400);
  --color-border-warning: var(--yellow-500);
  /*link*/
  --color-link-default: var(--blue-400);
  --color-link-active: var(--blue-700);
  --color-link-hover: var(--blue-500);
  /*primary/secondary/tertiary*/
  --color-primary-default-from: var(--color-secondary-default);
  --color-primary-default-to: var(--color-tertiary-default);
  --color-primary-light-from: var(--color-secondary-light);
  --color-primary-light-to: var(--color-tertiary-light);

  --color-primary-dark: var(--color-tertiary-dark);
  --color-primary-light: linear-gradient(
    90deg,
    var(--color-primary-light-from) 33.95% var(--color-primary-light-to) 111.2%
  );
  --color-primary-default: linear-gradient(
    90deg,
    var(--color-primary-default-from) 0%,
    var(--color-primary-default-to) 100%
  );
  --color-secondary-dark: var(--teal-700);
  --color-secondary-default: var(--teal-500);
  --color-secondary-light: var(--teal-50);
  --color-tertiary-dark: var(--blue-700);
  --color-tertiary-default: var(--blue-500);
  --color-tertiary-light: var(--blue-50);
  /*skeleton*/
  --color-skeleton-foreground-from: var(--neutral-300);
  --color-skeleton-foreground-to: var(--neutral-400);
  /*text*/
  --color-text-info: var(--blue-700);
  --color-text-negative: var(--red-800);
  --color-text-placeholder: var(--neutral-300);
  --color-text-positive: var(--green-800);
  --color-text-primary: var(--neutral-800);
  --color-text-primary-inverse: var(--neutral-50);
  --color-text-secondary: var(--neutral-600);
  --color-text-secondary-inverse: var(--neutral-400);
  --color-text-warning: var(--yellow-800);
}
